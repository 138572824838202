import React from 'react'
import { StaticQuery } from 'gatsby'
import _ from 'lodash'
import Layout from '../../components/layout'
import PageFooter from '../../components/PageFooter'
import Image from '../../components/Image'
import FullWidthHeader from '../../components/FullWidthHeader'

import mapMarkdownRemarks, {
  findFirstEntryWithTags,
} from '../../components/mapMarkdownRemarks'
import { Row, Col } from 'react-flexbox-grid'

import withAdminLinks from '../../utils/withAdminLinks';

function Div(props) {
  return <div className={`section ${props.className}`} style={props.style}>
    {props.children}
  </div>
}

function Section(props) {
  return <div className="section" {...props}></div>
}

const ContentWrapper = withAdminLinks(Div);

const MobileLabPage = (params) => {
  const { vision, challenge, supportUsCta, language } = params;

  return (
    <Layout language={language}>
      <ContentWrapper node={vision} className="section" style={{ marginBottom: 20 }}>
        <div className="container">
          <Row>
            <Col>
              <Row style={{ backgroundColor: '#f4f4f8', borderRadius: 10 }}>
                <Col md="5" sm="12" style={{ paddingLeft: 0 }}>
                  <Image
                    height="100%"
                    width="100%"
                    mode="fill"
                    src={_.get(vision.images, '[0]')}
                  />
                </Col>
                <Col md="5" sm="12" style={{ padding: 0, alignSelf: 'center' }}>
                  <Row>
                    <Col xs={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
                      <h2 style={{}}>{vision.title}</h2>
                      <div
                        className="md-content"
                        dangerouslySetInnerHTML={{ __html: vision.html }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Section>
            <div className="container">
              <Row center="xs">
                <Col xs={12} sm={9}>
                  <a name="1" style={{ visibility: 'hidden' }} />
                  <div
                    className="md-content"
                    dangerouslySetInnerHTML={{ __html: challenge.html }}
                  />
                </Col>
              </Row>
            </div>
          </Section>
        </div>
      </ContentWrapper>
      {supportUsCta && (
        <FullWidthHeader
          node={supportUsCta}
          title={supportUsCta.title}
          subtitle={supportUsCta.shortExcerpt}
          linkTo={supportUsCta.link}
        />
      )}
      <PageFooter language={language} />
    </Layout>
  )
}

const MobileLabPageQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        vision: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#mobile-lab-vision" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        challenge: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#mobile-lab-content" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
      }
    `}
    render={data => {
      const language = _.get(props.pageContext, 'language')

      const vision = _.first(mapMarkdownRemarks(data.vision.edges || [], language))
      const challenge = _.first(mapMarkdownRemarks(data.challenge.edges || [], language))


      const supportUsCta = vision
        ? findFirstEntryWithTags(vision, ['#mobile-lab-support-cta'])
        : null

      return (
        <MobileLabPage
          language={language}
          vision={vision}
          supportUsCta={supportUsCta}
          challenge={challenge}
          {...props}
        />
      )
    }}
  />
)

export default MobileLabPageQuery
